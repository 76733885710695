<template>
  <v-dialog
    v-if="gateReportEventsValidationStore.eventsValidationDialog"
    v-model="gateReportEventsValidationStore.eventsValidationDialog"
    persistent
    fullscreen
    content-class="overflow-hidden"
    :transition="false"
    @keydown.esc="close"
  >
    <!-- HEADER -->
    <v-card tile class="d-flex flex-column h-100">
      <v-card-title ref="header" class="align-center py-2" size="20">
        <div class="text-h5">Events validation &nbsp;</div>
        <div class="text-subtitle-2">
          ({{ gateReportStore.selectedProject.name }} -
          {{ gateReportStore.selectedDate }})
        </div>

        <!-- Open in dashboard button -->
        <ImpersonateUserButton
          class="ml-4"
          :email="gateReportStore.selectedProject.owner.email"
          :target-route="openInDashUrl"
          tooltip="Open in dashboard"
        />

        <!-- Close button -->
        <v-btn
          class="close-icon"
          color="primary"
          icon
          dark
          large
          @click="close"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <!-- EDITOR -->
      <v-card-text
        v-if="gateReportEventsValidationStore.eventsValidationDialog"
        ref="content"
        v-resize-observer="debouceHandleResize"
        class="py-0 pr-0 px-3"
      >
        <EventsValidationEditor :style="contentStyle" />
      </v-card-text>

      <!-- FOOTER -->
      <v-card-actions
        ref="footer"
        class="pt-0 pb-1 px-0 mt-auto d-flex flex-column"
      >
        <v-divider class="w-100"></v-divider>
        <div class="d-flex justify-space-between w-100">
          <div class="legend pt-4 pl-2">
            <v-badge bottom inline left :color="'#5eb135'">
              <div class="black--text text--lighten-1">Model events</div>
            </v-badge>
            <v-badge bottom inline left color="#FF5252">
              <div class="black--text text--lighten-1">Motion events</div>
            </v-badge>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue"
import EventsValidationEditor from "@/components/gateReport/editor/EventsValidationEditor"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import { debounce } from "@evercam/shared/utils"
import { useGateReportEventsValidationStore } from "@/stores/gateReportEventsValidation"
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"

export default Vue.extend({
  name: "EventsValidationDialog",
  components: {
    EventsValidationEditor,
    ImpersonateUserButton,
  },
  data() {
    return {
      contentStyle: {},
    }
  },
  computed: {
    ...mapStores(useGateReportEventsValidationStore, useGateReportStore),
    openInDashUrl() {
      return `/projects/${
        this.gateReportStore.selectedProject?.exid
      }/gate-report?selectedDate=${this.$moment(
        this.gateReportStore.selectedDate
      ).format("YYYY-MM-DD")}`
    },
  },
  methods: {
    open() {
      this.gateReportEventsValidationStore.eventsValidationDialog = true
    },
    close() {
      this.gateReportEventsValidationStore.eventsValidationDialog = false
    },
    debouceHandleResize: debounce(function () {
      // @ts-ignore
      this.handleResize()
    }),
    handleResize() {
      const header = this.$refs.header?.getBoundingClientRect()
      const footer = this.$refs.footer?.getBoundingClientRect() || { height: 0 }
      if (!header || !footer) {
        return
      }
      this.contentStyle = {
        height: `${window.innerHeight - header.height - footer.height}px`,
      }
    },
  },
})
</script>
