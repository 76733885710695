<template>
  <div class="unknown-events border-radius-8 overflow-hidden position-relative">
    <!-- CAROUSEL -->
    <v-carousel
      :value="selectedEventId"
      hide-delimiters
      show-arrows
      :mandatory="false"
      @change="onEventSelected"
    >
      <img
        alt="thumbnail"
        :src="backgroundImage"
        class="position-absolute d-block w-100 thumbnail__background-img"
      />
      <EZoomable>
        <v-carousel-item
          v-for="event in unknownEvents"
          :key="event.id"
          :value="event.id"
          :src="getSnapshotUrl(event)"
          contain
        >
        </v-carousel-item>
      </EZoomable>
      <div
        v-if="selectedEvent && duplicateEventsMap[selectedEvent.id]"
        ref="duplicatesContainer"
        class="unknown-events__duplicates"
      >
        <ProbableDuplicateCard
          v-for="e in duplicateEventsMap[selectedEvent.id]"
          :key="`duplicate-${e.id}`"
          :probable-duplicate="e"
          :original-event="selectedEvent"
          :timezone="timezone"
          :token="token"
        />
      </div>
    </v-carousel>

    <!-- ACTIONS -->
    <ERow no-gutters class="unknown-events__actions">
      <!-- DISMISS -->
      <ECol col="6">
        <v-btn
          block
          color="error"
          :style="buttonStyle"
          :disabled="!isUnknownEvent"
          @click="onEventDismissed"
        >
          <v-icon class="mr-2"> fas fa-trash </v-icon>
          Dismiss
        </v-btn>
      </ECol>

      <!-- VALIDATE -->
      <ECol
        ref="validationSheet"
        v-resize-observer="debounceHandleValidationSheetResize"
        col="6"
      >
        <v-sheet
          color="#bbe6a5"
          class="validation-sheet pa-2 d-inline-flex justify-center flex-wrap"
        >
          <v-chip
            v-for="{ name, id } in gateReportStore.vehicleTypes"
            :key="id"
            small
            label
            tag="div"
            pill
            :disabled="selectedVehicleType === id"
            class="ma-1 justify-around align-center"
            :class="{
              'd-none': id === 'unknown',
              'd-inline-flex': id !== 'unknown',
              'cursor-pointer': selectedVehicleType !== id,
            }"
            color="#5db033"
            @click="onVehicleTypeSelected(id)"
          >
            <div class="d-flex align-center">
              <ESvgIcon
                :icon="id"
                size="30"
                class="ml-n2 pb-1"
                color="#193b00"
              />
              <span>{{ name }}</span>
            </div>
          </v-chip>
        </v-sheet>
      </ECol>
    </ERow>

    <!-- LOADER -->
    <div
      v-if="loading"
      class="unknown-events__loader-overlay position-absolute z-index-1 h-100 w-100 d-flex justify-center align-center"
    >
      <v-progress-circular
        :size="50"
        :width="4"
        color="primary"
        indeterminate
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { GateReportEvent, GateReportVehicleType } from "@evercam/shared/types"
import ProbableDuplicateCard from "@/components/gateReport/editor/ProbableDuplicateCard"
import { AiApi } from "@evercam/shared/api/aiApi"
import { useGateReportStore } from "@/stores/gateReport"
import { debounce } from "@evercam/shared/utils"
import { mapStores } from "pinia"

export default Vue.extend({
  name: "EventsValidationCarousel",
  components: {
    ProbableDuplicateCard,
  },
  props: {
    selectedEvent: {
      type: Object as PropType<GateReportEvent>,
      default: () => ({}),
    },
    events: {
      type: Array as PropType<GateReportEvent[]>,
      default: () => [],
    },
    token: {
      type: String,
      default: "",
    },
    timezone: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    duplicateEventsMap: {
      type: Object as PropType<{ [eventId: number | string]: GateReportEvent }>,
      default: () => ({}),
    },
  },

  data() {
    return {
      buttonMinHeight: null,
    }
  },
  computed: {
    ...mapStores(useGateReportStore),
    unknownEvents() {
      return this.events.filter(
        (e) => e.truckType === GateReportVehicleType.Unknown
      )
    },
    selectedEventId() {
      return this.selectedEvent?.id || this.events[0]?.id
    },
    selectedVehicleType() {
      return this.selectedEvent?.truckType
    },
    buttonStyle() {
      if (!this.buttonMinHeight) {
        return
      }

      return {
        minHeight: `${this.buttonMinHeight}px`,
        height: `${this.buttonMinHeight}px`,
        borderRadius: 0,
        borderBottomLeftRadius: "8px",
      }
    },
    isUnknownEvent() {
      return this.selectedEvent.truckType === GateReportVehicleType.Unknown
    },
    backgroundImage() {
      if (this.selectedEvent.id) {
        return this.getSnapshotUrl(this.selectedEvent as GateReportEvent)
      } else {
        return ""
      }
    },
  },
  methods: {
    getSnapshotUrl(event: GateReportEvent) {
      if (!event.id) {
        return ""
      }

      return AiApi.gateReport.getEventSnapshot({
        cameraExid: event.cameraExid,
        eventType: event.eventType,
        eventTime: event.eventTime || event.timestamp,
        token: this.token,
      })
    },
    onEventSelected(id): void {
      this.$emit("event-selected", id)
    },
    onVehicleTypeSelected(vehicleTypeId: GateReportVehicleType) {
      if (this.selectedVehicleType === vehicleTypeId) {
        return
      }

      this.$emit("event-validated", vehicleTypeId)
    },
    onEventDismissed(vehicleTypeId: GateReportVehicleType) {
      this.$emit("event-dismissed", vehicleTypeId)
    },
    debounceHandleValidationSheetResize: debounce(function (contentRect) {
      // @ts-ignore
      this.handleValidationSheetResize(contentRect)
    }, 200),
    handleValidationSheetResize({ contentRect }) {
      this.buttonMinHeight = contentRect.height
    },
  },
})
</script>

<style lang="scss">
$shadow: rgba(0, 0, 0, 0.2) 0 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
  rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

.unknown-events {
  box-shadow: $shadow;
  &__actions {
    .validation-sheet,
    .validation-sheet .v-chip {
      box-shadow: $shadow;
      border-bottom-right-radius: 8px;
    }
  }
  &__loader-overlay {
    top: 0;
    background: #ddd7;
  }
  &__duplicates {
    bottom: 0;
    left: 0;
    margin: 2.5px;
    position: absolute;
    transition: left 0.25s, right 0.25s;
    &--rtl {
      right: 0;
      left: auto;
    }
  }
  .thumbnail__background-img {
    transform: scale(2);
    filter: blur(8px);
  }
}
</style>
