<template>
  <v-progress-linear
    :value="(100 * parsedScore) / maxScore"
    :color="color"
    height="100%"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span v-if="!isNaN(parsedScore)">
      {{ parsedScore }}
      / {{ maxScore }}
    </span>
    <span v-else> - </span>
  </v-progress-linear>
</template>

<script>
export default {
  name: "ScoreBar",
  props: {
    score: {
      type: [Number, String, null],
      default: 0,
    },
    maxScore: {
      type: Number,
      default: 10,
    },
    colors: {
      type: Array,
      default: () => [
        "#fc9f9f",
        "#ffb396",
        "#eed59d",
        "#d6e3a4",
        "#b4d984",
        "#8cc96b",
        "#7cdc58",
      ],
    },
  },
  computed: {
    parsedScore() {
      return Number.parseFloat(Number.parseFloat(this.score).toFixed(1))
    },
    color() {
      return this.colors[
        Math.floor(((this.colors.length - 1) * this.score) / this.maxScore)
      ]
    },
  },
}
</script>
