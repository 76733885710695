var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"events-grid mx-4"},[_c('HoverEventThumbnailContainer',{attrs:{"hovered-thumbnail":_vm.hoveredThumbnail}}),_vm._v(" "),_c('div',{staticClass:"events-grid__header"},_vm._l((_vm.headers),function(header){return _c('div',{key:header.value,staticClass:"column font-weight-500",class:{
        'column--sortable': header.sortable,
        'arrow-up': !_vm.isDesc,
        'arrow-down': _vm.isDesc,
        'is-selected': _vm.sortedColumn === header.value,
        [header.className || '']: true,
      },style:(header.value === 'thumbnail' ? _vm.headerThumbnailStyle : {}),on:{"click":function($event){return _vm.sort(header.value)}}},[_vm._v("\n      "+_vm._s(header.text)+"\n    ")])}),0),_vm._v(" "),(_vm.isTaskInProgress || _vm.gateReportStore.isLoadingEvents)?_c('v-divider'):_vm._e(),_vm._v(" "),(_vm.isTaskInProgress || _vm.gateReportStore.isLoadingEvents)?_c('div',[_c('div',{staticClass:"w-100 d-flex justify-center pt-4"},[_c('TasksProgressIndicator',{attrs:{"tasks":_vm.tasksProgressItems}})],1)]):_c('v-list-item-group',{directives:[{name:"resize-observer",rawName:"v-resize-observer",value:(_vm.handleResize),expression:"handleResize"}],ref:"gridContainer",staticClass:"overflow-y-auto overflow-x-hidden events-grid__list",on:{"hook:mounted":_vm.handleResize}},[(!_vm.events.length)?_c('div',{staticClass:"d-flex w-100 justify-center py-4"},[_vm._v("\n      No events to show.\n    ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.events),function(item){return _c('v-lazy',{key:item.id,staticClass:"w-100",attrs:{"options":{
        threshold: 0.5,
      },"min-height":"55","height":"55","transition":"fade-transition"}},[_c('GateReportEventCard',{staticClass:"w-100",class:`selected-event-${item.id}`,attrs:{"event":item,"show-id":"","show-is-edited":"","show-event-type":"","show-detection-type":"","show-delete-button":false,"thumbnail-width":_vm.minThumbnailsWidth},on:{"click":function($event){return _vm.openEventsValidationDialog(item)},"thumbnail-width":_vm.onThumbnailWidthMeasured,"thumbnail-hovered":_vm.handleHoveredThumbnail}})],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }