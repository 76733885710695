<template>
  <div>
    <v-expand-transition>
      <div
        v-if="!isMinimized"
        class="unknown-events__duplicate border-radius-6 d-flex overflow-hidden"
      >
        <img
          class="unknown-events__duplicate__thumbnail"
          :src="thumbnailUrl"
          alt="duplicate event thumbnail"
        />
        <div class="unknown-events__duplicate__legend pl-3 pt-2">
          <div class="d-flex justify-between pb-1">
            <div class="font-weight-500 pt-1">Probable duplicate</div>
            <div class="ml-auto mr-2">
              <v-btn
                rounded
                fab
                x-small
                light
                depressed
                @click="toggleMinimized"
              >
                <v-icon>fa-minus</v-icon>
              </v-btn>
            </div>
          </div>
          <div>
            <span class="font-weight-500">id:</span>
            {{ probableDuplicate.id }}
            <div>
              <span class="font-weight-500">Time:</span>
              {{ formattedTimestamp }}
            </div>
          </div>
          <div>
            <span class="font-weight-500">Time diff:</span>
            <span class="subtitle-2">{{ formattedTimeDiff }}</span>
          </div>
          <div>
            <span class="font-weight-500">Vehicle type:</span>
            {{ probableDuplicate.truckType }}
          </div>
        </div>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <div
        v-if="isMinimized"
        class="unknown-events__duplicate border-radius-6 d-flex overflow-hidden"
      >
        <div class="d-flex justify-between align-center pl-3">
          <div><strong>Duplicate id: </strong> {{ probableDuplicate.id }}</div>
          <div class="mx-2">|</div>
          <div><strong>time: </strong> {{ formattedTimestamp }}</div>
          <div class="mx-2">|</div>
          <div><strong>diff: </strong> {{ formattedTimeDiff }}</div>
          <div class="mx-2">|</div>
          <div><strong>type: </strong> {{ probableDuplicate.truckType }}</div>

          <div class="ml-auto pl-2 pr-1">
            <v-btn x-small light depressed @click="toggleMinimized">
              <v-icon>fa-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { GateReportEvent } from "@evercam/shared/types"
import { AiApi } from "@evercam/shared/api/aiApi"

export default Vue.extend({
  name: "ProbableDuplicateCard",
  props: {
    token: {
      type: String,
      default: "",
    },
    timezone: {
      type: String,
      default: "",
    },
    probableDuplicate: {
      type: Object as PropType<GateReportEvent>,
      default: () => ({}),
    },
    originalEvent: {
      type: Object as PropType<GateReportEvent>,
      default: () => ({}),
    },
  },
  data() {
    return {
      isMinimized: false,
    }
  },
  computed: {
    thumbnailUrl() {
      if (!this.probableDuplicate.id) {
        return ""
      }

      return AiApi.gateReport.getEventThumbnail({
        cameraExid: this.probableDuplicate.cameraExid,
        eventType: this.probableDuplicate.eventType,
        eventTime:
          this.probableDuplicate.eventTime || this.probableDuplicate.timestamp,
        token: this.token,
      })
    },
    formattedTimestamp(): string {
      return this.$moment
        .tz(this.probableDuplicate.eventTime, this.timezone)
        .format("YYYY-MM-DDTHH:mm:ss")
    },
    formattedTimeDiff(): string {
      const selectedEventTime = this.$moment(this.originalEvent.eventTime)
      const duplicateEventTime = this.$moment(this.probableDuplicate.eventTime)

      const diffInSeconds = duplicateEventTime.diff(
        selectedEventTime,
        "seconds"
      )

      if (diffInSeconds === 0) {
        return "0"
      } else {
        const sign = diffInSeconds > 0 ? "( + )" : "( - )"
        const absoluteDiff = Math.abs(diffInSeconds)
        const pluralizeSeconds = absoluteDiff === 1 ? " second" : " seconds"

        return `${sign} ${absoluteDiff}${pluralizeSeconds}`
      }
    },
  },
  methods: {
    toggleMinimized() {
      this.isMinimized = !this.isMinimized
    },
  },
})
</script>

<style lang="scss">
.unknown-events__duplicate {
  margin: 2.5px;
  background: #ddd;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.1) 0 8px 10px -6px;
  &__legend {
    width: 200px;
  }
}
</style>
