<template>
  <ERow ref="container" class="events-validation-editor d-flex flex-nowrap">
    <ECol
      :cols="$vuetify.breakpoint.xlOnly ? 7 : 6"
      class="pr-0 d-flex flex-column flex-nowrap h-100 pb-0 pl-0"
    >
      <div class="events-validation-editor__hours overflow-auto pl-2">
        <!-- Model events Cameras heatmap bar -->
        <EHeatmapBar
          v-if="gateReportStore.selectedProject.cameras.length > 1"
          class="mt-5"
          :items="modelEventsCamerasHeatmapItems"
          :selected-value="camera.exid"
          dense
          :colors="modelEventsHeatmapColors"
          @change="onCameraSelected"
        >
          <template #label>
            <v-badge bottom inline left :color="'#5eb135'" size="10" />
          </template>
        </EHeatmapBar>

        <!-- Manual events Cameras heatmap bar -->
        <EHeatmapBar
          v-if="gateReportStore.selectedProject.cameras.length > 1"
          class="mb-2 mt-0"
          :items="motionEventsCamerasHeatmapItems"
          :selected-value="camera.exid"
          :show-names="false"
          :colors="motionEventsHeatmapColors"
          dense
          @change="onCameraSelected"
        >
          <template #label>
            <v-badge bottom inline left :color="'#FF5252'" size="10" />
          </template>
        </EHeatmapBar>

        <!-- Model events hours heatmap -->
        <EHoursHeatmap
          class="mt-6"
          :items="modelEventsHoursHeatmapItems"
          :selected-hour="gateReportEventsValidationStore.selectedHour"
          dense
          :colors="modelEventsHeatmapColors"
          @change="onHourChange"
        >
          <template #label>
            <v-badge bottom inline left :color="'#5eb135'" size="10" />
          </template>
        </EHoursHeatmap>

        <!-- New events hours heatmap -->
        <EHoursHeatmap
          class="mb-2 mt-0"
          :items="motionEventsHoursHeatmapItems"
          :selected-hour="gateReportEventsValidationStore.selectedHour"
          :show-hours="false"
          :colors="motionEventsHeatmapColors"
          dense
          @change="onHourChange"
        >
          <template #label>
            <v-badge bottom inline left :color="'#FF5252'" size="10" />
          </template>
        </EHoursHeatmap>
      </div>

      <!-- UNKNOWN EVENTS CAROUSEL -->
      <div class="events-validation-editor__carousel pl-3">
        <EventsValidationCarousel
          :events="eventsByHour[gateReportEventsValidationStore.selectedHour]"
          :selected-event="gateReportEventsValidationStore.editedEvent"
          :disabled="!isMotionDetectionEvent"
          :loading="isCarouselLoading"
          :token="accountStore.token"
          :timezone="timezone"
          :duplicate-events-map="duplicateEventsMap"
          @event-selected="onCarouselEventSelected"
          @event-validated="onMotionEventValidated"
          @event-dismissed="onMotionEventDismissed"
        />
      </div>
    </ECol>

    <ECol
      :cols="$vuetify.breakpoint.xlOnly ? 5 : 6"
      class="events-validation-editor__sidebar d-flex flex-column overflow-x-hidden pr-1 h-100 pb-0 pl-1"
    >
      <EventsValidationEventsList
        :key="gateReportEventsValidationStore.selectedHour"
        :events="eventsListItems"
        :lazy="false"
        class="events-validation-editor__events-list h-100 overflow-y-auto pt-0 pl-2"
        is-motion-detection
        :loading-events-ids="gateReportEventsValidationStore.loadingEventsIds"
        :saved-events-ids="gateReportEventsValidationStore.savedEventsIds"
        :errored-events-ids="gateReportEventsValidationStore.erroredEventsIds"
        :duplicate-events-ids="duplicateEventsIds"
        :edited-events-ids="editedEventsIds"
        :deleted-events-ids="deletedEventsIds"
        :edited-event="gateReportEventsValidationStore.editedEvent"
        :is-editable="false"
        @click="onEventSelected"
        @dismiss-selected-events="onDismissSelectedEvents"
      />
    </ECol>
  </ERow>
</template>

<script lang="ts">
import Vue from "vue"
import EventsValidationEventsList from "@/components/gateReport/editor/EventsValidationEventsList"
import EventsValidationCarousel from "@/components/gateReport/editor/EventsValidationCarousel"
import {
  AdminCamera,
  CameraExid,
  GateReportEvent,
  GateReportVehicleType,
} from "@evercam/shared/types"
import { useGateReportEventsValidationStore } from "@/stores/gateReportEventsValidation"
import { useGateReportStore } from "@/stores/gateReport"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"
import { HeatmapBarItem, HoursHeatmapChartItem } from "@evercam/ui"

export default Vue.extend({
  name: "EventsValidationEditor",
  components: {
    EventsValidationCarousel,
    EventsValidationEventsList,
  },
  data() {
    return {
      selectedCamera: null as AdminCamera | null,
      modelEventsHeatmapColors: [
        "#ebedf0",
        "#e3f7b8",
        "#acd576",
        "#84c33c",
        "#64b113",
      ],
      motionEventsHeatmapColors: [
        "#EBEDF0",
        "#F0C6C9",
        "#F5A0A1",
        "#FA797A",
        "#FF5252",
      ],
      isCarouselLoading: false,
      editedEventsIds: [],
      deletedEventsIds: [],
    }
  },
  computed: {
    ...mapStores(
      useGateReportEventsValidationStore,
      useGateReportStore,
      useAccountStore
    ),
    eventsListItems(): GateReportEvent[] {
      const eventsByHour =
        this.eventsByHour[this.gateReportEventsValidationStore.selectedHour] ||
        []

      return eventsByHour.filter(this.isMotionEvent)
    },
    duplicateEventsMap(): { [eventId: number | string]: GateReportEvent } {
      const events =
        this.eventsByHour[this.gateReportEventsValidationStore.selectedHour] ||
        []
      let duplicateIdsMap = {}
      for (let i = 0; i < events.length; i++) {
        for (let j = i + 1; j < events.length; j++) {
          const event1 = events[i]
          const event2 = events[j]

          const areEventsDistinct = event1.id !== event2.id
          const areEventsFromSameCamera =
            event1.cameraExid === event2.cameraExid
          const isFirstEventMotion = this.isMotionEvent(event1)
          const isSecondEventMotion = this.isMotionEvent(event2)
          const areEventsOfDifferentSources =
            (isFirstEventMotion && !this.isMotionEvent(event2)) ||
            (isSecondEventMotion && !this.isMotionEvent(event1))

          if (
            areEventsDistinct &&
            areEventsFromSameCamera &&
            areEventsOfDifferentSources &&
            this.areEventsWithinTimeWindow(event1, event2)
          ) {
            const motionEvent = isFirstEventMotion ? event1 : event2
            const modelEvent = isFirstEventMotion ? event2 : event1
            duplicateIdsMap = {
              ...duplicateIdsMap,
              [motionEvent.id]: [
                ...(duplicateIdsMap[motionEvent.id] || []),
                { ...modelEvent },
              ],
            }
          }
        }
      }

      return duplicateIdsMap
    },
    duplicateEventsIds(): number[] {
      return Object.keys(this.duplicateEventsMap).map((id) =>
        Number.parseInt(id)
      )
    },
    isMotionDetectionEvent(): boolean {
      return (
        this.gateReportEventsValidationStore.editedEvent &&
        this.gateReportEventsValidationStore.editedEvent?.truckType ===
          GateReportVehicleType.Unknown
      )
    },
    filteredEvents() {
      return this.gateReportStore.getFilteredEvents({
        events: this.gateReportEventsValidationStore.events,
        searchParams: this.gateReportStore.searchParams,
      })
    },
    modelEventsHoursHeatmapItems(): HoursHeatmapChartItem[] {
      return this.availableHours.map((h) => ({
        timestamp: new Date().setHours(h),
        count: (this.eventsByHour[h] || []).filter((e) => {
          return !this.isMotionEvent(e)
        }).length,
      }))
    },
    motionEventsHoursHeatmapItems(): HoursHeatmapChartItem[] {
      return this.availableHours.map((h) => ({
        timestamp: new Date().setHours(h),
        count: (this.eventsByHour[h] || []).filter((e) => {
          return this.isMotionEvent(e)
        }).length,
      }))
    },
    eventsByHour(): { [hour: string]: GateReportEvent[] } {
      return (
        this.eventsByCamera[this.camera.exid]?.reduce((acc, event) => {
          const hour = this.$moment
            .tz(event.eventTime, this.timezone)
            .format("H")

          return { ...acc, [hour]: [...(acc[hour] || []), event] }
        }, {}) || []
      )
    },
    eventsByCamera(): { [exid: CameraExid]: GateReportEvent[] } {
      const emptyEventsByCamerasMap =
        this.gateReportStore.selectedCameras.reduce((acc, c) => {
          return { ...acc, [c.exid]: [] }
        }, {})

      return this.gateReportEventsValidationStore.events.reduce(
        (acc, event) => {
          const exid = event.cameraExid || ""
          if (!emptyEventsByCamerasMap[exid]) {
            return acc
          }

          return {
            ...acc,
            [exid]: [...(acc[exid] || []), event],
          }
        },
        emptyEventsByCamerasMap
      )
    },
    modelEventsCamerasHeatmapItems(): HeatmapBarItem[] {
      return Object.entries(this.eventsByCamera).reduce(
        (acc, [exid, events]) => {
          return [
            ...acc,
            {
              value: exid,
              // @ts-ignore (see https://github.com/microsoft/TypeScript/issues/38520 )
              count: events.filter((e) => !this.isMotionEvent(e)).length,
              name: this.getCameraByExid(exid)?.name,
            },
          ]
        },
        []
      )
    },
    motionEventsCamerasHeatmapItems(): HeatmapBarItem[] {
      return Object.entries(this.eventsByCamera).reduce(
        (acc, [exid, events]) => {
          return [
            ...acc,
            {
              value: exid,
              // @ts-ignore (see https://github.com/microsoft/TypeScript/issues/38520 )
              count: events.filter((e) => this.isMotionEvent(e)).length,
              name: this.getCameraByExid(exid)?.name,
            },
          ]
        },
        []
      )
    },
    camera: {
      get(): AdminCamera {
        return this.selectedCamera &&
          this.getCameraByExid(this.selectedCamera?.exid)
          ? this.selectedCamera
          : this.gateReportStore.selectedCameras[0]
      },
      set(newValue: AdminCamera): void {
        this.selectedCamera = newValue
      },
    },
    timezone(): string {
      return this.gateReportStore.selectedProject?.timezone || "Europe/Dublin"
    },
    availableHours(): string[] {
      return Object.keys(this.eventsByHour)
    },
    motionEvents() {
      return this.gateReportEventsValidationStore.events.filter(
        this.isMotionEvent
      )
    },
  },
  watch: {
    "gateReportEventsValidationStore.preselectedEventFromUrl": {
      immediate: true,
      handler(event) {
        if (!event?.id) {
          return
        }

        const selectedCameraExid = this.selectedCamera?.exid
        const isDifferentCamera = selectedCameraExid !== event.cameraExid
        if (isDifferentCamera) {
          this.selectedCamera = this.getCameraByExid(event.cameraExid)
        }

        const editedEventHour = this.$moment
          .tz(event.eventTime, this.timezone)
          .hour()
        const isDifferentHour =
          editedEventHour !== this.gateReportEventsValidationStore.selectedHour
        if (isDifferentHour) {
          this.gateReportEventsValidationStore.selectedHour = editedEventHour
        }

        this.onEventSelected(event)
      },
    },
    "gateReportEventsValidationStore.selectedHour": {
      immediate: true,
      handler(selectedHour) {
        this.$setTimeout(() => {
          const editedEventHour = this.$moment
            .tz(
              this.gateReportEventsValidationStore.editedEvent?.eventTime,
              this.timezone
            )
            .hour()
          const editedEventCameraExid =
            this.gateReportEventsValidationStore.editedEvent?.cameraExid
          const selectedCameraExid = this.selectedCamera?.exid
          const isEventPreSelected =
            editedEventCameraExid === selectedCameraExid &&
            (editedEventHour === selectedHour || !selectedHour)

          if (!isEventPreSelected && this.eventsListItems?.length) {
            this.gateReportEventsValidationStore.editedEvent =
              this.eventsListItems[0]
          }
        })
      },
    },
    eventsByHour(eventsByHour) {
      if (
        eventsByHour[this.gateReportEventsValidationStore.selectedHour]?.length
      ) {
        return
      }

      const hourWithEvents = Object.entries(eventsByHour).find(
        ([_hour, events]) => this.getMotionEvents(events).length
      )

      if (hourWithEvents) {
        this.gateReportEventsValidationStore.selectedHour = parseInt(
          hourWithEvents[0]
        )
      }
    },
    eventsByCamera(eventsByCamera) {
      if (eventsByCamera[this.camera.exid]?.length) {
        return
      }
      const [cameraWithEvents] =
        Object.entries(eventsByCamera).find(
          // @ts-ignore (see https://github.com/microsoft/TypeScript/issues/38520 )
          ([_exid, events]) => {
            return this.getMotionEvents(events).length > 0
          }
        ) || []

      if (cameraWithEvents) {
        this.camera = this.getCameraByExid(cameraWithEvents)
      }
    },
  },
  beforeMount() {
    this.init()
  },
  methods: {
    init() {
      this.initEvents()
      this.initSelectedCamera()
      this.initSelectedHour()
    },
    initEvents() {
      this.gateReportEventsValidationStore.events = [
        ...this.gateReportStore.events,
      ].sort((e1, e2) => {
        return this.$moment(e1.eventTime).isBefore(e2.eventTime) ? -1 : 1
      })
    },
    initSelectedCamera() {
      if (this.gateReportEventsValidationStore.editedEvent?.exid) {
        this.selectedCamera = this.getCameraByExid(
          this.gateReportEventsValidationStore.editedEvent?.cameraExid
        )
      } else if (this.gateReportEventsValidationStore.events[0]?.exid) {
        this.selectedCamera = this.getCameraByExid(
          this.gateReportEventsValidationStore.events[0]?.exid
        )
      } else {
        this.selectedCamera = this.gateReportStore.selectedCameras[0]
      }
    },
    initSelectedHour() {
      if (this.gateReportEventsValidationStore.editedEvent?.eventTime) {
        this.gateReportEventsValidationStore.selectedHour = this.$moment
          .tz(
            this.gateReportEventsValidationStore.editedEvent?.eventTime,
            this.timezone
          )
          ?.hours()
      } else if (this.availableHours.length) {
        this.gateReportEventsValidationStore.selectedHour = Number.parseInt(
          this.availableHours[0]
        )
      } else if (this.camera?.hours?.length) {
        this.gateReportEventsValidationStore.selectedHour = Number.parseInt(
          this.camera.hours[0]
        )
      }
    },
    getMotionEvents(events: GateReportEvent[]) {
      return events.filter((e) => e.truckType === GateReportVehicleType.Unknown)
    },
    isMotionEvent(e = {} as GateReportEvent): boolean {
      return e?.truckType === GateReportVehicleType.Unknown
    },
    onHourChange(hour) {
      this.gateReportEventsValidationStore.selectedHour = hour
    },
    getCameraByExid(exid: CameraExid): AdminCamera {
      return this.gateReportStore.selectedCameras.find((c) => c.exid === exid)
    },
    onEventSelected(event: GateReportEvent) {
      if (!event) {
        return
      }

      if (this.gateReportEventsValidationStore.editedEvent?.id !== event.id) {
        this.gateReportEventsValidationStore.editedEvent = {
          ...event,
        }
      }
    },
    onCameraSelected(exid: CameraExid) {
      this.camera = this.getCameraByExid(exid)
    },
    onCarouselEventSelected(eventId) {
      if (eventId) {
        const events =
          this.eventsByHour[this.gateReportEventsValidationStore.selectedHour]
        const targetEvent = events.find((e) => e.id === eventId)
        this.onEventSelected(targetEvent)
      }
    },
    areEventsWithinTimeWindow(event1, event2) {
      const timeWindow = 5
      const timestamp1 = this.$moment(event1.eventTime)
      const timestamp2 = this.$moment(event2.eventTime)

      const duration = Math.abs(timestamp1.diff(timestamp2, "seconds"))

      return duration <= timeWindow
    },
    getNextEvent() {
      const findNext = (events) =>
        (events || []).find((e) =>
          this.$moment(e.eventTime).isAfter(
            this.$moment(
              this.gateReportEventsValidationStore.editedEvent?.eventTime
            )
          )
        )

      const findFirstDistinct = (events) =>
        (events || []).find(
          (e) => e.id !== this.gateReportEventsValidationStore.editedEvent?.id
        )

      const selectedHourMotionEvents = this.eventsByHour[
        this.gateReportEventsValidationStore.selectedHour
      ].filter((e) => e.truckType === GateReportVehicleType.Unknown)
      const selectedCameraMotionEvents = this.eventsByCamera[
        this.selectedCamera?.exid
      ].filter((e) => e.truckType === GateReportVehicleType.Unknown)
      const allMotionEvents = this.motionEvents

      return (
        findNext(selectedHourMotionEvents) ||
        findFirstDistinct(selectedHourMotionEvents) ||
        findNext(selectedCameraMotionEvents) ||
        findFirstDistinct(selectedCameraMotionEvents) ||
        findNext(allMotionEvents) ||
        findFirstDistinct(allMotionEvents)
      )
    },
    handleAsyncCarouselAction(asyncActionCallback: Function) {
      const eventId = this.gateReportEventsValidationStore.editedEvent.id
      this.gateReportEventsValidationStore.loadingEventsIds = [
        ...this.gateReportEventsValidationStore.loadingEventsIds,
        eventId,
      ]

      const nextEvent = this.getNextEvent()
      if (nextEvent) {
        this.gateReportEventsValidationStore.editedEvent = { ...nextEvent }
      }

      asyncActionCallback().then(() => {
        this.gateReportEventsValidationStore.loadingEventsIds =
          this.gateReportEventsValidationStore.loadingEventsIds.filter(
            (id) => id !== eventId
          )
        this.isCarouselLoading = false
      })
    },
    onMotionEventValidated(vehicleTypeId: GateReportVehicleType) {
      const event: GateReportEvent = {
        ...this.gateReportEventsValidationStore.editedEvent,
      }
      this.editedEventsIds = [...this.editedEventsIds, event.id]
      this.handleAsyncCarouselAction(async () => {
        const updatedEvent = {
          ...event,
          truckType: vehicleTypeId,
          isPublic: true,
        }
        await this.gateReportEventsValidationStore.updateEvent(updatedEvent)
      })
    },
    onMotionEventDismissed() {
      const event = { ...this.gateReportEventsValidationStore.editedEvent }
      this.deletedEventsIds = [...this.deletedEventsIds, event.id]
      this.handleAsyncCarouselAction(async () => {
        await this.gateReportEventsValidationStore.deleteEvent(event)
      })
    },
    onDismissSelectedEvents(events) {
      this.gateReportEventsValidationStore.dismissEvents(events)
    },
  },
})
</script>

<style lang="scss">
.events-validation-editor {
  position: relative;
  max-width: 100%;
  &__hours {
    display: table;
    .hours-heatmap__item {
      min-width: 0;
    }
    .hours-heatmap__label {
      width: 6rem;
      min-width: 6rem;
    }
  }
  &__carousel {
    min-height: 0;
    align-content: flex-start;
  }
  &__events-list {
    overflow: auto;
  }
}
</style>
