export default [
  {
    text: "Thumbnail",
    value: "thumbnail",
    sortable: false,
    className: "ml-2",
  },
  {
    text: "Truck type",
    value: "truckType",
    sortable: true,
  },
  {
    text: "Event Time",
    value: "eventTime",
    sortable: true,
  },
  {
    text: "Event Type",
    value: "eventType",
    sortable: true,
    className: "ml-n2",
  },
  {
    text: "Verified",
    value: "isPublic",
    sortable: true,
  },
  {
    text: "Camera",
    value: "cameraExid",
    sortable: true,
  },
  {
    text: "Id",
    width: 80,
    value: "id",
    align: "center",
    sortable: true,
    class: "gate-report-table__header",
  },
  {
    text: "Edited",
    value: "isEdited",
    sortable: true,
  },
  {
    text: "Detection Type",
    value: "detectionType",
    sortable: true,
  },
]
