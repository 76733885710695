<template>
  <v-list ref="container" class="events-validation-events">
    <HoverEventThumbnailContainer
      :hovered-thumbnail="hoveredThumbnail"
      display-position="left"
    />
    <div v-if="!events.length" class="d-flex justify-center pt-5">
      {{ isMotionDetection ? "No unknown events to show ✅" : "No events" }}
    </div>
    <v-list-item-group v-else @hook:mounted="onListRendered">
      <div class="d-flex e-justify-between align-center py-2 mt-2">
        <v-checkbox
          v-model="selectAll"
          :label="selectAllLabel"
          class="resize-checkbox ma-0 pa-0"
          hide-details
          @change="selectAllEvents"
        ></v-checkbox>
        <v-btn
          v-if="selectedEventsIds.length"
          color="error"
          small
          @click="dismissSelectedEvents"
        >
          <v-icon class="mr-2"> fas fa-trash </v-icon>
          {{ dismissAllLabel }}
        </v-btn>
      </div>
      <component
        :is="lazy ? 'v-lazy' : 'div'"
        v-for="event in events"
        :key="event.id"
        ref="events"
        :component="lazy ? 'v-lazy' : 'div'"
        :data-id="event.id"
        :options="{
          threshold: 0.5,
        }"
        min-height="55"
        height="55"
        transition="fade-transition"
        class="w-100"
        @click="$emit('click', event)"
      >
        <div class="event-card">
          <div class="checkbox-and-card d-flex align-center">
            <v-checkbox
              v-model="selectedEventsIds"
              type="checkbox"
              :value="event"
              hide-details
              class="mt-0 resize-checkbox"
              @change="updateSelectAll"
            ></v-checkbox>
            <GateReportEventCard
              :ref="`event-${event.id}`"
              :event="event"
              :is-new="isNewEvent(event)"
              :is-active="isEventActive(event)"
              :is-loading="isEventLoading(event)"
              :is-saved="isEventSaved(event) || isEventManual(event)"
              :is-errored="isEventErrored(event)"
              :is-selected="isEventSelected(event)"
              :is-editable="isEditable"
              :is-edited="isEventEdited(event)"
              :is-deleted="isEventDeleted(event)"
              :is-duplicate="isEventDuplicate(event)"
              :class="{ 'pointer-events-none': isEventLoading(event) }"
              :show-camera-exid="false"
              :show-id="isMotionDetection"
              show-camera-name
              :show-delete-button="isEventDeletable(event)"
              :thumbnail-width="minThumbnailsWidth"
              :show-snapshot-preview="false"
              @mouseenter="onMouseEnter(event)"
              @mouseleave="onMouseLeave(event)"
              @delete-event="$emit('delete-event', event)"
              @ignore-event="$emit('ignore-event', event)"
              @visibility-change="$emit('visibility-change', event)"
              @thumbnail-width="onThumbnailWidthMeasured"
              @thumbnail-hovered="handleHoveredThumbnail"
            />
          </div>
        </div>
      </component>
    </v-list-item-group>
  </v-list>
</template>

<script>
import GateReportEventCard from "@/components/gateReport/GateReportEventCard"
import HoverEventThumbnailContainer from "@/components/gateReport/HoverEventThumbnailContainer"
import { GateReportDetectionType } from "@evercam/shared/types"

export default {
  name: "EventsValidationEventsList",
  components: {
    GateReportEventCard,
    HoverEventThumbnailContainer,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    activeEventsIds: {
      type: Array,
      default: () => [],
    },
    loadingEventsIds: {
      type: Array,
      default: () => [],
    },
    savedEventsIds: {
      type: Array,
      default: () => [],
    },
    erroredEventsIds: {
      type: Array,
      default: () => [],
    },
    editedEventsIds: {
      type: Array,
      default: () => [],
    },
    deletedEventsIds: {
      type: Array,
      default: () => [],
    },
    duplicateEventsIds: {
      type: Array,
      default: () => [],
    },
    visibleEventsIds: {
      type: Array,
      default: () => [],
    },
    editedEvent: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isMotionDetection: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      minThumbnailsWidth: undefined,
      hoveredThumbnail: { isHovered: false, url: false },
      selectedEventsIds: [],
      selectAll: false,
      selectedReason: null,
    }
  },
  computed: {
    selectAllLabel() {
      return this.selectAll ? "Unselect All" : "Select All"
    },
    dismissAllLabel() {
      return this.selectAll ? "Dismiss All" : "Dismiss Selected"
    },
  },
  watch: {
    editedEvent: {
      immediate: true,
      handler(event) {
        if (!this.isMotionDetection) {
          return
        }
        this.$setTimeout(() => this.scrollToEvent(event?.id))
      },
    },
    selectedEventsIds: {
      handler: function (newValue) {
        // Check if selectedEventsIds array is empty
        if (newValue.length === 0) {
          // If it's empty, set selectedReason to null
          this.selectedReason = null
        }
      },
      deep: true, // Necessary if selectedEventsIds is an array
    },
  },
  methods: {
    onListRendered() {
      if (!this.editedEvent || !this.isMotionDetection) {
        return
      }

      // Find the edited event and scroll to it
      const eventsRefs = this.$refs.events
      const targetEventRef = (eventsRefs || []).find((e) => {
        const eventId = e?.$el?.dataset?.id

        return Number.parseInt(eventId) === this.editedEvent.id
      })

      if (targetEventRef) {
        this.$setTimeout(() => {
          targetEventRef?.$el.scrollIntoViewIfNeeded()
        }, 500)
      }
    },
    isEventSelected(event) {
      return this.editedEvent && this.editedEvent?.id === event.id
    },
    isEventActive(event) {
      return this.activeEventsIds.includes(event.id || event.tempId)
    },
    isEventLoading(event) {
      return this.loadingEventsIds.includes(event.id || event.tempId)
    },
    isEventSaved(event) {
      return this.savedEventsIds.includes(event.id || event.tempId)
    },
    isEventErrored(event) {
      return this.erroredEventsIds.includes(event.id || event.tempId)
    },
    isEventManual(event) {
      return (
        event.detectionType === GateReportDetectionType.Manual &&
        !this.isNewEvent(event)
      )
    },
    isEventDeletable(event) {
      return event.detectionType === GateReportDetectionType.Manual
    },
    isEventEdited(event) {
      return this.editedEventsIds.includes(event.id)
    },
    isEventDeleted(event) {
      return this.deletedEventsIds.includes(event.id)
    },
    isEventDuplicate(event) {
      return this.duplicateEventsIds.includes(event.id)
    },
    isNewEvent(event) {
      return !!event.tempId
    },
    onMouseEnter(event) {
      this.$emit("mouseenter", event)
    },
    onMouseLeave(event) {
      this.$emit("mouseleave", event)
    },
    onThumbnailWidthMeasured(width) {
      if (!this.minThumbnailsWidth || width > this.minThumbnailsWidth) {
        this.minThumbnailsWidth = width
      }
    },
    handleHoveredThumbnail(hoveredThumbnail) {
      this.hoveredThumbnail = hoveredThumbnail
    },
    scrollToEvent(eventId) {
      const [el] = this.$refs[`event-${eventId}`] || []
      const eventCard = el?.$el?.getBoundingClientRect()
      const container = this.$refs.container?.$el?.getBoundingClientRect()

      if (!eventCard || !container) {
        return
      }

      if (
        eventCard.top < container.top ||
        eventCard.bottom > container.bottom
      ) {
        el.$el.scrollIntoView({ behavior: "smooth" })
      }
    },
    updateSelectAll() {
      this.selectAll = this.selectedEventsIds.length === this.events.length
    },
    selectAllEvents() {
      if (this.selectAll) {
        this.selectedEventsIds = this.events
      } else {
        this.selectedEventsIds = []
        this.selectedReason = null
      }
    },
    dismissSelectedEvents() {
      this.$emit(
        "dismiss-selected-events",
        this.selectedEventsIds,
        this.selectedReason
      )
      this.selectedEventsIds = []
      this.selectAll = false
      this.selectedReason = null
    },
  },
}
</script>

<style lang="scss">
@import "~@evercam/shared/styles/mixins";
.events-validation-events {
  @include custom-scrollbar();
  .grc-direction,
  .grc-exid,
  .grc-timestamp {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .grc-actions {
    align-items: flex-end;
    margin-left: auto;
    width: 30px;
    transform: translateX(-60px);
    order: 1;
    .single-event-verifier {
      justify-content: flex-end !important;
      position: absolute;
      left: 40px;
    }
  }
  .gr-event-card__fields-container {
    display: grid !important;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
    grid-template-rows: 48px;
    & > * {
      display: flex;
      align-items: center;
    }
  }
  .thumbnail-container .thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkbox-and-card {
    display: flex;
  }
  .reasons-checkbox {
    margin: 0px 10px;
  }
}
</style>
