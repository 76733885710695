<template>
  <div class="d-flex justify-center single-event-verifier">
    <v-switch
      v-model="isVerified"
      dense
      inset
      input-value="true"
      class="mr-0"
      color="#53a263"
      :loading="isLoading"
      v-bind="$attrs"
      @change="toggleEventVisibility()"
    >
    </v-switch>
  </div>
</template>

<script>
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"

export default {
  name: "SingleEventVerifier",
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVerified: false,
      isLoading: false,
      editedEvent: {},
    }
  },
  computed: {
    ...mapStores(useGateReportStore),
  },
  watch: {
    event: {
      deep: true,
      immediate: true,
      handler() {
        this.isVerified = !!this.event.isPublic
      },
    },
  },
  methods: {
    async toggleEventVisibility() {
      const isEditedEvent = Object.keys(this.editedEvent).length
      const isVerified = isEditedEvent
        ? !this.editedEvent.isPublic
        : !this.event.isPublic
      this.editedEvent = {
        ...(isEditedEvent ? this.editedEvent : this.event),
        isPublic: isVerified,
      }
      this.isLoading = true
      const response = await this.gateReportStore.verifySingleEvent(
        this.editedEvent
      )
      this.isLoading = false
      if (response) {
        this.gateReportStore.events = this.gateReportStore.events.map((e) =>
          e.id === this.editedEvent.id ? this.editedEvent : e
        )
      }
      this.$emit("success", this.editedEvent)
    },
  },
}
</script>

<style lang="scss">
.single-event-verifier {
  .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
}
</style>
