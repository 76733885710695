<template>
  <ECol class="pa-0">
    <ERow no-gutters class="align-start justify-start">
      <!-- Selected cameras  -->
      <ECol
        :class="{
          'flex-grow-0': gateReportStore.cameras.length <= 2,
        }"
      >
        <div class="font-weight-500">Cameras</div>
        <!-- All cameras -->
        <v-chip
          x-small
          outlined
          label
          class="mr-1 cursor-pointer"
          :color="allCamerasSelected ? 'primary' : 'secondary'"
          @click="allCamerasSelected = !allCamerasSelected"
        >
          All cameras
        </v-chip>

        <!-- Single cameras -->
        <v-chip
          v-for="{ name, exid } in gateReportStore.cameras"
          :key="exid"
          x-small
          outlined
          label
          class="mr-1 cursor-pointer"
          :color="
            (gateReportStore.selectedCameraExids || []).includes(exid)
              ? 'primary'
              : 'secondary'
          "
          @click="toggleCamera(exid)"
        >
          {{ name }} - ({{ exid }})
        </v-chip>
      </ECol>

      <!-- Selected truck types  -->
      <ECol class="pl-2">
        <div class="font-weight-500">Truck types</div>

        <!-- All truck types -->
        <v-chip
          x-small
          outlined
          label
          class="mr-1 cursor-pointer"
          :color="allVehiclesSelected ? 'primary' : 'secondary'"
          @click="allVehiclesSelected = !allVehiclesSelected"
        >
          All trucks
        </v-chip>

        <!-- Single truck type -->
        <v-chip
          v-for="{ name, id } in gateReportStore.vehicleTypes"
          :key="id"
          x-small
          outlined
          label
          tag="div"
          pill
          class="mr-1 cursor-pointer d-inline-flex justify-around align-center"
          :color="
            (gateReportStore.selectedVehicleTypes || []).includes(id)
              ? 'primary'
              : 'secondary'
          "
          @click="toggleVehicleType(id)"
        >
          <div class="d-flex align-center">
            <ESvgIcon
              :icon="id"
              size="30"
              class="pb-1 ml-n2"
              :color="
                (gateReportStore.selectedVehicleTypes || []).includes(id)
                  ? '#0D47A1'
                  : '#757575'
              "
            />
            <span>{{ name }}</span>
          </div>
        </v-chip>
      </ECol>

      <!-- Selected verified events visibility  -->
      <ECol cols="2">
        <div class="font-weight-500">Visibility</div>
        <v-chip
          v-for="{
            text,
            value,
          } in gateReportStore.verifiedEventsVisibilityItems"
          :key="value"
          x-small
          outlined
          label
          class="mr-1 cursor-pointer"
          :color="
            gateReportStore.selectedVerifiedEventsVisibility === value ||
            gateReportStore.selectedVerifiedEventsVisibility === 0
              ? 'primary'
              : 'secondary'
          "
          @click="gateReportStore.selectedVerifiedEventsVisibility = value"
        >
          {{ text }}
        </v-chip>
      </ECol>
    </ERow>
  </ECol>
</template>

<script>
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"

export default {
  name: "EventsFiltersSummary",
  computed: {
    ...mapStores(useGateReportStore),
    allCamerasSelected: {
      get() {
        return (
          this.gateReportStore.selectedCameraExids.length ===
          this.gateReportStore.cameras.length
        )
      },
      set(selectAll) {
        if (selectAll) {
          this.gateReportStore.selectedCameraExids =
            this.gateReportStore.cameras.map((c) => c.exid)
        } else {
          this.gateReportStore.selectedCameraExids = []
        }
      },
    },
    allVehiclesSelected: {
      get() {
        return (
          this.gateReportStore.selectedVehicleTypes.length ===
          this.gateReportStore.vehicleTypes.length
        )
      },
      set(selectAll) {
        if (selectAll) {
          this.gateReportStore.selectedVehicleTypes =
            this.gateReportStore.vehicleTypes.map((v) => v.id)
        } else {
          this.gateReportStore.selectedVehicleTypes = []
        }
      },
    },
  },
  methods: {
    toggleCamera(exid) {
      if (this.gateReportStore.selectedCameraExids.includes(exid)) {
        this.gateReportStore.selectedCameraExids =
          this.gateReportStore.selectedCameraExids.filter((c) => c !== exid)
      } else {
        this.gateReportStore.selectedCameraExids = [
          ...this.gateReportStore.selectedCameraExids,
          exid,
        ]
      }
    },
    toggleVehicleType(id) {
      if (this.gateReportStore.selectedVehicleTypes.includes(id)) {
        this.gateReportStore.selectedVehicleTypes =
          this.gateReportStore.selectedVehicleTypes.filter((v) => v !== id)
      } else {
        this.gateReportStore.selectedVehicleTypes = [
          ...this.gateReportStore.selectedVehicleTypes,
          id,
        ]
      }
    },
  },
}
</script>
