var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ECol',{staticClass:"pa-0"},[_c('ERow',{staticClass:"align-start justify-start",attrs:{"no-gutters":""}},[_c('ECol',{class:{
        'flex-grow-0': _vm.gateReportStore.cameras.length <= 2,
      }},[_c('div',{staticClass:"font-weight-500"},[_vm._v("Cameras")]),_vm._v(" "),_c('v-chip',{staticClass:"mr-1 cursor-pointer",attrs:{"x-small":"","outlined":"","label":"","color":_vm.allCamerasSelected ? 'primary' : 'secondary'},on:{"click":function($event){_vm.allCamerasSelected = !_vm.allCamerasSelected}}},[_vm._v("\n        All cameras\n      ")]),_vm._v(" "),_vm._l((_vm.gateReportStore.cameras),function({ name, exid }){return _c('v-chip',{key:exid,staticClass:"mr-1 cursor-pointer",attrs:{"x-small":"","outlined":"","label":"","color":(_vm.gateReportStore.selectedCameraExids || []).includes(exid)
            ? 'primary'
            : 'secondary'},on:{"click":function($event){return _vm.toggleCamera(exid)}}},[_vm._v("\n        "+_vm._s(name)+" - ("+_vm._s(exid)+")\n      ")])})],2),_vm._v(" "),_c('ECol',{staticClass:"pl-2"},[_c('div',{staticClass:"font-weight-500"},[_vm._v("Truck types")]),_vm._v(" "),_c('v-chip',{staticClass:"mr-1 cursor-pointer",attrs:{"x-small":"","outlined":"","label":"","color":_vm.allVehiclesSelected ? 'primary' : 'secondary'},on:{"click":function($event){_vm.allVehiclesSelected = !_vm.allVehiclesSelected}}},[_vm._v("\n        All trucks\n      ")]),_vm._v(" "),_vm._l((_vm.gateReportStore.vehicleTypes),function({ name, id }){return _c('v-chip',{key:id,staticClass:"mr-1 cursor-pointer d-inline-flex justify-around align-center",attrs:{"x-small":"","outlined":"","label":"","tag":"div","pill":"","color":(_vm.gateReportStore.selectedVehicleTypes || []).includes(id)
            ? 'primary'
            : 'secondary'},on:{"click":function($event){return _vm.toggleVehicleType(id)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('ESvgIcon',{staticClass:"pb-1 ml-n2",attrs:{"icon":id,"size":"30","color":(_vm.gateReportStore.selectedVehicleTypes || []).includes(id)
                ? '#0D47A1'
                : '#757575'}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(name))])],1)])})],2),_vm._v(" "),_c('ECol',{attrs:{"cols":"2"}},[_c('div',{staticClass:"font-weight-500"},[_vm._v("Visibility")]),_vm._v(" "),_vm._l((_vm.gateReportStore.verifiedEventsVisibilityItems),function({
          text,
          value,
        }){return _c('v-chip',{key:value,staticClass:"mr-1 cursor-pointer",attrs:{"x-small":"","outlined":"","label":"","color":_vm.gateReportStore.selectedVerifiedEventsVisibility === value ||
          _vm.gateReportStore.selectedVerifiedEventsVisibility === 0
            ? 'primary'
            : 'secondary'},on:{"click":function($event){_vm.gateReportStore.selectedVerifiedEventsVisibility = value}}},[_vm._v("\n        "+_vm._s(text)+"\n      ")])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }